import React from "react";
import '../../App.css';
import Footer from "../Footer";

export default function ContactMe() {

return (
        <>
            <h3 className="contact-me">Say Hi!</h3>
            
            <Footer />
        </>
    )
};